<div class="container">
  <h1 class="brush">Tufty Games</h1>

  <div class="games">
    <div class="weekend game card-shadow">
      <h4 class="brush">The Weekend</h4>
      <p class="game-description">A teenager heads to the coast to visit his sister for the weekend.  A coming-of-age story with a twist.</p>
      <p class="status alert alert-warning d-inline-block">v0.1.9: Work in Progress</p>
      <div class="controls">
        <a class="btn btn-primary" href="games/weekend-v2-0.1.9.html">Play!</a>
      </div>
    </div>
    <div class="weekend game card-shadow">
      <h4 class="brush">Thin Walls</h4>
      <p class="game-description">An old friend's fiancé comes to visit with some questions about the past.</p>
      <p class="status alert alert-warning d-inline-block">v1.1.0: Complete - With More To Come</p>
      <div class="controls">
        <a class="btn btn-primary" href="games/thin-walls-1.1.html">Play!</a>
      </div>
    </div>
  </div>

  <div class="support">
    <a class="patreon" href="https://www.patreon.com/bePatron?u=4338500">
      <svg class="logo mr-2" viewBox="0 0 569 546" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Patreon logo</title>
        <g>
          <circle data-color="1" fill="white" id="Oval" cx="362.589996" cy="204.589996" r="204.589996"></circle>
          <rect data-color="2" id="Rectangle" x="0" y="0" width="100" height="545.799988"></rect>
        </g>
      </svg>Support Me!</a>
  </div>
</div>
